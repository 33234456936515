const MAMISERVICE_ORDER_LINK = 'https://mkos.app/PesanMamiservice';
const MAMIKOS_APP_STORES = [
  {
    name: 'Google Play',
    label: 'GET IT ON Google Play',
    redirectUrl:
      'https://play.google.com/store/apps/details?id=com.git.mami.kos&utm_campaign=DAppAndroFooter&utm_source=DownloadAppFooter&utm_medium=DownloadAppFooter&utm_term=DownloadAppFooter',
    imgSrc: '@/assets/img/home/download_gplay.png',
    rel: 'nofollow noopener',
  },
  {
    name: 'App Store',
    label: 'Available on the App Store',
    redirectUrl: 'https://itunes.apple.com/id/app/mami-kos/id1055272843',
    imgSrc: '@/assets/img/home/download_ios.png',
    rel: 'nofollow noopener',
  },
];
const MAMIKOS_LINKS = [
  {
    name: 'Tentang Kami',
    url: '/tentang-kami',
  },
  {
    name: 'Job Mamikos',
    url: '/career',
  },
  {
    name: 'Promosikan Kost Anda',
    url: '/promosi-kost',
  },
  {
    name: 'Pusat Bantuan',
    url: 'https://help.mamikos.com/',
    rel: 'nofollow noopener',
  },
];
const MAMIKOS_PRODUCT_LINKS = [
  {
    name: 'Blog Mamikos',
    url: 'https://mamikos.com/info',
    rel: 'nofollow noopener',
  },
  {
    name: 'Singgahsini',
    url: 'https://singgahsini.id/',
    rel: 'nofollow noopener',
  },
];
const MAMIKOS_POLICY_LINKS = [
  {
    name: 'Kebijakan Privasi',
    url: 'https://help.mamikos.com/post/kebijakan-privasi-mamikos',
    rel: 'nofollow noopener',
  },
  {
    name: 'Syarat dan Ketentuan Umum',
    url: '/syarat-dan-ketentuan',
    rel: 'nofollow noopener',
  },
];
const MAMIKOS_SOCIAL_LINKS = [
  {
    name: 'Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/Mamikos-Cari-Kos-Gampang-1043376389026435/?fref=ts',
    rel: 'nofollow noopener',
  },
  {
    name: 'Twitter',
    icon: 'twitter',
    url: 'https://twitter.com/mamikosapp',
    rel: 'nofollow noopener',
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/mamikosapp/',
    rel: 'nofollow noopener',
  },
];
const MAMIKOS_MOBILE_LINKS = [
  {
    name: 'Tentang Kami',
    url: '/tentang-kami',
  },
  {
    name: 'Pusat Bantuan',
    url: 'https://help.mamikos.com/',
    rel: 'nofollow noopener',
  },
  {
    name: 'Kebijakan Privasi',
    url: 'https://help.mamikos.com/post/kebijakan-privasi-mamikos',
    rel: 'nofollow noopener',
  },
  {
    name: 'Syarat dan Ketentuan Umum',
    url: 'https://help.mamikos.com/post/syarat-dan-ketentuan-umum',
    rel: 'nofollow noopener',
  },
];

export {
  MAMISERVICE_ORDER_LINK,
  MAMIKOS_APP_STORES,
  MAMIKOS_LINKS,
  MAMIKOS_PRODUCT_LINKS,
  MAMIKOS_POLICY_LINKS,
  MAMIKOS_SOCIAL_LINKS,
  MAMIKOS_MOBILE_LINKS,
};
