//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgIcon, BgDropdown, BgDropdownItem, BgButtonIcon } from 'Bangul-vue';

import Viewport from '@/mixins/viewport';
import { MAMISERVICE_WHATSAPP_NUMBER } from '@/constants/contact';
import { MAMISERVICE_ORDER_LINK } from '@/constants/config';

export default {
  name: 'NavigationBar',

  components: {
    BgIcon,
    BgDropdown,
    BgDropdownItem,
    BgButtonIcon,
  },

  mixins: [Viewport],

  data() {
    return {
      isNavbarDropdownOpened: false,
      isMenuOpen: false,
      orderNowItems: [
        {
          label: 'Perawatan AC',
          url: MAMISERVICE_ORDER_LINK,
        },
        {
          label: 'Kebersihan Rumah, Apartemen, dan Kosan',
          url: MAMISERVICE_ORDER_LINK,
        },
        {
          label: 'Laundry',
          url: MAMISERVICE_ORDER_LINK,
        },
        {
          label: 'Aksesoris Kamar',
          url: `https://wa.me/${MAMISERVICE_WHATSAPP_NUMBER}`,
        },
      ],
      menuItems: [
        {
          label: 'Promo',
          routerName: 'promo',
        },
        {
          label: 'Cara Pesan',
          sectionId: 'cara-pesan-mamiservice',
        },
        {
          label: 'Tentang Kami',
          sectionId: 'tentang-kami',
        },
        {
          label: 'FAQ',
          sectionId: 'faq',
        },
      ],
    };
  },

  computed: {
    shouldShowPesanSekarangMobileButton() {
      return this.viewport.isMobile && !this.isMenuOpen;
    },
    rootClasses() {
      return {
        'mamiservice-navbar--mobile': this.viewport.isMobile,
        'mamiservice-navbar--open': this.isMenuOpen,
      };
    },
    toggleMenuIcon() {
      return this.isMenuOpen ? 'close' : 'hamburger-menu';
    },
    orderButtonLabel() {
      if (this.viewport.isMobile) {
        return 'Pesan';
      }

      return 'Pesan Disini';
    },
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleclickMenu(item) {
      if (this.viewport.isMobile) this.toggleMenu();

      if (item.routerName) {
        this.$router.push({ name: item.routerName });
      } else if (item.sectionId) {
        const isOnHomepage = this.$route.path === '/';

        if (isOnHomepage) {
          this.goToSection(item.sectionId);
        } else {
          this.$router.push({
            path: `/`,
          });

          setTimeout(() => {
            this.goToSection(item.sectionId);
          }, 50);
        }
      }
    },
    goToSection(target) {
      const mobileYOffset = -660;
      const desktopYOffset = -130;
      const yOffset = this.viewport.isMobile ? mobileYOffset : desktopYOffset;
      const element = document.getElementById(target);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
};
