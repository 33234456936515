//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgGrid, BgGridItem, BgAccordion, BgText } from 'Bangul-vue';

import Viewport from '@/mixins/viewport';

export default {
  name: 'HomeArticle',

  components: {
    BgGrid,
    BgGridItem,
    BgAccordion,
    BgText,
  },

  mixins: [Viewport],

  data() {
    return {
      activeAccordionIds: [],
      title: 'MamiService - Layanan Perawatan Kos, Apartemen, dan Rumah',
      aboutSectionContent:
        'Kesulitan membersihkan hunian tempat tinggal sendiri? Bingung memilih perabot yang cocok untuk ruangan? Pusing dengan cucian yang menumpuk? Atau ingin mencari layanan perbaikan AC yang tepercaya? Sebagai pemilik ataupun penghuni properti, masalah-masalah seperti ini bisa terselesaikan dengan jasa perawatan properti <i>online</i> melalui layanan MamiService.<br /><br />MamiService adalah layanan perawatan properti, seperti kos, apartemen, dan rumah. Jasa yang ditawarkan sangat lengkap meliputi <i>cleaning service</i>, <i>laundry</i>, perawatan AC, dan aksesoris kos.',
      sections: [
        {
          title: 'Mengapa Pilih MamiService?',
          leading:
            'Ada banyak keuntungan yang bisa Anda dapatkan dengan menggunakan layanan MamiService, seperti:<br /><br />',
          contents: [
            {
              title: 'Garansi Kepuasan',
              description:
                'MamiService memberikan garansi hingga 3 hari serta menjamin perbaikan secara tuntas sesuai keinginan.',
            },
            {
              title: 'Staf Berpengalaman dan Sudah Terlatih',
              description:
                'Anda tidak perlu khawatir dengan layanan yang diberikan MamiService karena staf yang ada di lapangan sudah berpengalaman.',
            },
            {
              title: 'Harga yang Lebih Terjangkau',
              description:
                'Dibandingkan jasa layanan serupa, layanan MamiService lebih terjangkau dan memberikan banyak keuntungan.',
            },
            {
              title: 'Metode Pemesanan yang Mudah dan Praktis dari Aplikasi',
              description:
                'Tanpa harus ke luar rumah, semua proses pemesanan dapat Anda lakukan dari rumah.',
            },
          ],
        },
        {
          title: 'Pilihan Layanan MamiService',
          leading:
            'Layanan Mamiservice yang dapat Anda pilih di antaranya:<br /><br />',
          ending:
            '<br />Jadi, apakah Anda sudah menentukan layanan MamiService yang akan dipilih?',
          contents: [
            {
              title: '<i>Cleaning service</i>',
              description:
                'Jasa <i>cleaning service</i> sangat cocok untuk Anda yang tidak sempat membersihkan properti sendiri. Dengan adanya jasa perawatan properti terdekat dari MamiService ini, Anda tidak perlu menunggu lama.<br />Harga paket <i>cleaning service</i> yang ditawarkan MamiService pun sangat ramah di kantong.',
            },
            {
              title: 'Jasa <i>Laundry</i>',
              description:
                'Layanan <i>pick-up laundry</i> dari MamiService akan menjamin <i>laundry</i> dijemput dan diantar ke lokasi. Pilih harga paket jasa <i>laundry</i> yang sesuai dengan kebutuhanmu.',
            },
            {
              title: 'Aksesoris kos',
              description:
                'Layanan aksesoris kos dari MamiService meliputi sofa <i>cushion</i>, sprei, rak kunci, dan keset.  Tentunya harga paket aksesoris kos yang diberikan MamiService dapat Anda sesuaikan kebutuhan.',
            },
            {
              title: 'Perawatan AC',
              description:
                'Jasa perawatan AC dari MamiService meliputi pembersihan AC, dan pengisian freon. Harga paket perawatan AC dari MamiService juga terjangkau.',
            },
          ],
        },
      ],
    };
  },

  computed: {
    whyMamiserviceSection() {
      return this.sections[0];
    },
    serviceListSection() {
      return this.sections[1];
    },
    sectionContentClass() {
      const shouldUseSmallClass = this.viewport.isMobile;

      if (shouldUseSmallClass) {
        return 'feature-section__content-small';
      }

      return 'feature-section__content';
    },
  },

  methods: {
    handleToggleAccordion(id) {
      if (this.isAcccordionActive(id)) {
        this.activeAccordionIds.splice(this.getAccordionIndex(id), 1);
      } else {
        this.activeAccordionIds.push(id);
      }
    },
    isAcccordionActive(id) {
      return this.getAccordionIndex(id) > -1;
    },
    getAccordionIndex(id) {
      return this.activeAccordionIds.indexOf(id);
    },
  },
};
