import axios from 'axios';
import { bugsnag } from '@/plugins/bugsnag';

export const makeAPICall = request => {
  return axios(request)
    .then(response => {
      if (response && response.status) {
        if (response.status === 200 && response.data) {
          return response.data;
        } else {
          bugsnag.report(`Error status: ${response.status}`);
        }
      }

      return null;
    })
    .catch(error => {
      bugsnag.report(error);

      return null;
    });
};
