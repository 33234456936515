import * as storage from '@/utils/storage';

/* istanbul ignore next: Jest can't simulate the event listener inside this function :( */
export function manageLastActivity() {
  let hiddenLabel = 'hidden';

  if (hiddenLabel in document) {
    document.addEventListener('visibilitychange', () =>
      updateLastActivity({ isHidden: document[hiddenLabel] })
    );
  } else if ((hiddenLabel = 'mozHidden') in document) {
    document.addEventListener('mozvisibilitychange', () =>
      updateLastActivity({ isHidden: document[hiddenLabel] })
    );
  } else if ((hiddenLabel = 'webkitHidden') in document) {
    document.addEventListener('webkitvisibilitychange', () =>
      updateLastActivity({ isHidden: document[hiddenLabel] })
    );
  } else if ((hiddenLabel = 'msHidden') in document) {
    document.addEventListener('msvisibilitychange', () =>
      updateLastActivity({ isHidden: document[hiddenLabel] })
    );
  }

  window.onpageshow =
    window.onfocus =
    document.focusin =
      () => {
        updateLastActivity({ isHidden: false });
      };

  window.onpagehide =
    window.onblur =
    document.focusout =
      () => {
        updateLastActivity({ isHidden: true });
      };
}

export function updateLastActivity({ isHidden }) {
  if (isHidden) {
    storage.local.setItem('last-activity', new Date().getTime());
  } else {
    storage.local.removeItem('last-activity');
  }
}
