//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgButtonIcon } from 'Bangul-vue';
import { MAMISERVICE_WHATSAPP_NUMBER } from '@/constants/contact';
import viewport from '@/mixins/viewport';

export default {
  name: 'FloatingContactSupportButton',
  components: {
    BgButtonIcon,
  },
  mixins: [viewport],
  methods: {
    redirectToWhatsapp() {
      const openedWindow = window.open(
        `https://wa.me/${MAMISERVICE_WHATSAPP_NUMBER}`
      );
      openedWindow.opener = null;
    },
  },
};
