import Vue from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';

const CONFIGURATION = {
  tnc: {
    ALLOWED_TAGS: ['b', 'i', 'a'],
  },
};

export function ApplyDOMPurify(customVue) {
  return customVue.use(VueDOMPurifyHTML, CONFIGURATION);
}

Vue.use(VueDOMPurifyHTML, CONFIGURATION);
