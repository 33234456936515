import { v4 as uuidv4 } from 'uuid';
import * as storage from '@/utils/storage';
import { makeAPICall } from '@/utils/api-caller';

export function isSessionValid() {
  const lastActivity =
    storage.local.getItem('last-activity') ||
    /* istanbul ignore next */ Date.now();
  const now = Date.now();
  const diffHour = Math.abs(
    Math.round((now - lastActivity) / 1000 / (60 * 60))
  );

  // if lastActivity <= 3 hours
  return diffHour <= 3;
}

export async function getTrackerSession() {
  let trackerSession = storage.local.getItem('tracker-session');
  const isLoggedIn = window.authCheck && window.authCheck?.all;
  // session from server is max 12 character and from uuid is 36
  const isGeneratedFromServer = trackerSession && trackerSession.length <= 12;

  if (trackerSession && isSessionValid()) {
    if (
      (isLoggedIn && isGeneratedFromServer) ||
      (!isLoggedIn && !isGeneratedFromServer)
    ) {
      return trackerSession;
    }
  }

  if (isLoggedIn) {
    const apiTrackerSessionURL = 'web_api/v3/tracker-session/active';
    const res = await makeAPICall({
      method: 'get',
      url: apiTrackerSessionURL,
      baseURL: '/',
    });

    if (res) {
      trackerSession = res['tracker-session'].session_id;
    }
  } else {
    trackerSession = uuidv4();
  }

  storage.local.setItem('tracker-session', trackerSession);
  return trackerSession;
}
