import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

const { BUGSNAG_API_KEY } = process.env;

const startVendorService = () => {
  if (BUGSNAG_API_KEY) {
    return Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginVue(Vue)],
    });
  }

  return { notify: () => {} };
};

const vendorInstance = startVendorService();

export const bugsnag = {
  report(error) {
    vendorInstance.notify(error);
  },
};

export default (_ctx, inject) => {
  inject('error', bugsnag);
};
