import Cookies from 'js-cookie';
import packageInfo from '../../package.json';
import { manageLastActivity } from './activity-manager';
import { getTrackerSession } from './tracker-session';
import { makeAPICall } from '@/utils/api-caller';

function currentUTCTime() {
  const today = new Date();
  const date = String(today.getUTCDate()).padStart(2, '0');
  const month = String(today.getUTCMonth() + 1).padStart(2, '0');
  const year = today.getUTCFullYear();
  const hours = String(today.getUTCHours()).padStart(2, '0');
  const minutes = String(today.getUTCMinutes()).padStart(2, '0');
  const seconds = String(today.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(today.getUTCMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

async function sendEventToInternalLogger(eventParams) {
  if (eventParams[0] === 'send' && eventParams[1] === 'pageview') {
    return;
  }

  const userId = window.authCheck?.all ? window.authData?.all?.id : null;
  const deviceId =
    Cookies && Cookies.get('USER_DATA')
      ? JSON.parse(Cookies.get('USER_DATA')).deviceUuid
      : null;
  const sessionId = await getTrackerSession();

  if (sessionId) {
    const trackingData = JSON.stringify({
      user: {
        user_id: userId,
        device_id: deviceId,
        session_id: sessionId,
        platform: window.innerWidth < 768 ? 'Web Mobile' : 'Web Desktop',
        app_version: packageInfo.version,
      },
      e: eventParams[0],
      event_time: currentUTCTime(),
      a: typeof eventParams[1] === 'object' ? eventParams[1] : eventParams[2],
      URL: window.location.href,
    });

    const apiLoggerUrl = process.env.MIX_API_LOGGER_URL;

    await makeAPICall({
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'x-api-key': '6QizyeWpdD3LXAs9f4CwE1zPrTY7EbNr4DVEGp82',
      },
      url: apiLoggerUrl,
      data: trackingData,
    });
  }
}

const trackerService = {
  moe: eventParams => {
    /**
     * Send event tracking to Moengage
     * https://docs.moengage.com/docs/tracking-website-events
     */
    Moengage.track_event.apply(Moengage, eventParams);

    // Dump event tracking name to Mamikos logger API and Google Analytic for logs
    sendEventToInternalLogger(eventParams);
    ga('send', {
      hitType: 'event',
      eventCategory: 'Event Tracking Log',
      eventAction: 'Record',
      eventLabel: eventParams[0],
    });
  },
  ga: eventParams => {
    /**
     * Send event tracking to Google Analytic
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference
     */
    ga.apply(window, eventParams);

    // Dump event tracking name to Mamikos logger API
    sendEventToInternalLogger(eventParams);
  },
  logger: eventParams => {
    /**
     * Send event tracking to Mamikos logger API
     */
    sendEventToInternalLogger(eventParams);
  },
};

/**
 * Global function from Mamikos tracker
 * e.g:
	- Moengage: 
		tracker('moe', [
				'[User] Booking - Click Booking',
				{
					property_type: this.detailTypeName ? this.detailTypeName : 'kost',
					property_name: _get(this.detail, 'room_title', null),
				}
			]);
	- Google Analytics:
			tracker('ga', [
				'send',
				'event',
				{
					eventCategory: 'Search v2',
					eventAction: 'suggestions-request',
					eventLabel: keyword
				}
			]);
 */
export function tracker(type, eventParams) {
  try {
    trackerService[type](eventParams);
  } catch (error) {
    console.error(error);
  }
}

// Expose tracker function to global
window.manageLastActivity = manageLastActivity;
window.tracker = tracker;

/* istanbul ignore next: Jest can't cover this lines */
window.onload = function () {
  window.manageLastActivity();
};
