//
//
//
//
//
//
//
//
//

import { BgFooter } from 'bangul-vue';
import Navbar from '@/components/global/NavigationBar';

export default {
  components: {
    Navbar,
    BgFooter,
    FloatingContactSupportButton: () =>
      import('~/components/global/FloatingContactSupportButton.vue'),
  },
};
