import { render, staticRenderFns } from "./without-home-article.vue?vue&type=template&id=cf707fc2&"
import script from "./without-home-article.vue?vue&type=script&lang=js&"
export * from "./without-home-article.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FloatingContactSupportButton: require('/builds/mamikos-fe/mamiservice-fe/components/global/FloatingContactSupportButton.vue').default})
