//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BgFooter, BgModal, BgImage } from 'Bangul-vue';
import Navbar from '@/components/global/NavigationBar';
import HomeArticle from '@/components/global/HomeArticle';

export default {
  components: {
    Navbar,
    HomeArticle,
    BgFooter,
    BgModal,
    BgImage,
    FloatingContactSupportButton: () =>
      import('~/components/global/FloatingContactSupportButton.vue'),
  },
  computed: {
    isSunsetted() {
      const envVal = process.env.SUNSETTED === 'on';
      return envVal;
    },
  },
};
